body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
}

.App {
  position: relative;
}

.Section {
  scroll-snap-align: start;
}

/* Universal font size for main text (section titles, main project titles, etc.) */
.Section h1, .contact-name, .contact-title, .project-link, .technologies-section {
  font-size: 28px; /* Main text set to 28px */
}

/* Subtext scaled down */
.project-description, .contact-email {
  font-size: 18px; /* Smaller subtext */
}

/* Back to Top Button */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #444;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 50px;
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.back-to-top:hover {
  opacity: 1;
  transform: translateY(-5px);
}

/* Project Link Hover Effect */
.project-link {
  transition: color 0.3s ease, text-shadow 0.3s ease;
  color: white;
  text-decoration: none;
}

.project-link:hover {
  color: #9c9c9c;
}

/* Contact Section */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contact-icons {
  display: flex;
  gap: 20px;
}

.contact-icon {
  color: white;
  font-size: 32px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.contact-icon:hover {
  color: #9c9c9c;
  transform: scale(1.1);
}

.typed-tech-element {
  font-weight: bold;
  color: #00ffcc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .Section h1, .project-link, .project-description, .contact-name, .contact-title, .contact-email, .technologies-section {
    font-size: 24px; /* Scale down the text on smaller screens */
  }
}

@media (max-width: 480px) {
  .Section h1, .project-link, .project-description, .contact-name, .contact-title, .contact-email, .technologies-section {
    font-size: 20px; /* Further scale down on very small screens */
  }
}
